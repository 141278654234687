var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merlends"},[_c('el-form',{ref:"queryForm",attrs:{"model":_vm.queryParams,"inline":true}},[_c('el-form-item',{attrs:{"prop":"storeName"}},[_c('el-input',{attrs:{"placeholder":"客户名称","clearable":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleQuery.apply(null, arguments)}},model:{value:(_vm.queryParams.storeName),callback:function ($$v) {_vm.$set(_vm.queryParams, "storeName", $$v)},expression:"queryParams.storeName"}})],1),_c('el-form-item',{attrs:{"prop":""}},[_c('el-cascader',{attrs:{"options":_vm.AreaList,"props":_vm.propsValue,"clearable":"","filterable":"","placeholder":"省/市/区县"},model:{value:(_vm.queryParams.addr),callback:function ($$v) {_vm.$set(_vm.queryParams, "addr", $$v)},expression:"queryParams.addr"}})],1),_c('el-form-item',{attrs:{"prop":"joined"}},[_c('el-select',{attrs:{"placeholder":"客户是否入驻","clearable":""},model:{value:(_vm.queryParams.joined),callback:function ($$v) {_vm.$set(_vm.queryParams, "joined", $$v)},expression:"queryParams.joined"}},[_c('el-option',{attrs:{"label":"是","value":1}}),_c('el-option',{attrs:{"label":"否","value":0}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleQuery}},[_vm._v(" 查询 ")])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"header-cell-style":{ background: '#F2F3F5' },"data":_vm.dataList,"border":""}},[_c('el-table-column',{attrs:{"prop":"storeName","label":"客户名称"}}),_c('el-table-column',{attrs:{"prop":"address","label":"客户位置"}}),_c('el-table-column',{attrs:{"prop":"","label":"添加时间|添加人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.createTime))]),_c('div',[_vm._v(_vm._s(row.salesmanName)+"/"+_vm._s(row.salesmanAccount))])]}}])}),_c('el-table-column',{attrs:{"prop":"","label":"关联供应商客户"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.customerName))]),_c('div',[_vm._v("单位内码："+_vm._s(row.erpCode))])]}}])}),_c('el-table-column',{attrs:{"prop":"","label":"是否入驻平台"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.joined))])]}}])}),_c('el-table-column',{attrs:{"prop":"","label":"审核状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm._f("auditType")(row.auditStatus)))])]}}])}),_c('el-table-column',{attrs:{"prop":"remark","label":"备注"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.auditStatus == 0 && row.userId > 0)?_c('span',[_c('span',{staticClass:"btsed",on:{"click":function($event){return _vm.changeStatus(row.id, 1)}}},[_vm._v("通过")]),_c('span',{staticClass:"btsed",on:{"click":function($event){return _vm.changeStatus(row.id, 2)}}},[_vm._v("拒绝")])]):_vm._e(),_c('span',{staticClass:"btsed",on:{"click":function($event){return _vm.deletes(row)}}},[_vm._v("删除")])])]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > 0),expression:"total > 0"}],attrs:{"total":_vm.total,"page":_vm.queryParams.page,"limit":_vm.queryParams.perPage},on:{"update:page":function($event){return _vm.$set(_vm.queryParams, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.queryParams, "perPage", $event)},"pagination":_vm.getList}}),_c('el-dialog',{attrs:{"title":"审核驳回提醒","visible":_vm.isRefuse,"close-on-click-modal":false,"width":"500px"},on:{"update:visible":function($event){_vm.isRefuse=$event}}},[_c('div',{staticClass:"fx"},[_c('div',{staticStyle:{"width":"100px"}},[_c('span',{staticClass:"red"},[_vm._v("*")]),_vm._v("驳回原因：")]),_c('el-input',{attrs:{"type":"textarea","rows":7,"placeholder":"请输入内容","maxlength":"200","show-word-limit":""},model:{value:(_vm.AuditMessage.remark),callback:function ($$v) {_vm.$set(_vm.AuditMessage, "remark", $$v)},expression:"AuditMessage.remark"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.isRefuse = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.notarizeRefuse}},[_vm._v("驳 回")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }